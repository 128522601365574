import _core from "../../modules/_core";
var exports = {};
var core = _core;
var $JSON = core.JSON || (core.JSON = {
  stringify: JSON.stringify
});

exports = function stringify(it) {
  // eslint-disable-line no-unused-vars
  return $JSON.stringify.apply($JSON, arguments);
};

export default exports;